import React, { useEffect, useState } from 'react'
import styles from './fileUploadWithChunk.module.css'
import io from 'socket.io-client'

const FileUploadWithChunk = (props) => {
  const [errorSend, setErrorSend] = useState(false);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('no file selected');
  const [statusUpload, setStatusUpload] = useState('');

  const url = props.url()
  const mainUrl = props.mainUrl
  const headers = props.headers()

  const maxFileSize = process.env.MAX_FILE_SIZE_FOR_UPLOAD || 1024 * 1024 * 1024

  const runError = function (message) {
    props.onError(message)
  }

  const runOnLoad = function (data) {
    props.onLoad(data)
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    const socketUrl=`wss${mainUrl().slice(mainUrl().indexOf('://'))}`
    const socket = io(`${socketUrl}?token=${token}`)
    const div = document.getElementById("progressBar")

    socket.on('uploadState', (msg) => {
      const message = JSON.parse(msg)
      switch (message.event) {
        case 'uploaded':
          div.style.width = `${message.data.percent}%`
          setStatusUpload(message.message)
          break
        case 'uploadToAzure':
          div.style.background = '#0996a1'
          setStatusUpload(message.message)
          break
        case 'updateChannel':
          div.style.background = '#916b0a'
          setStatusUpload(message.message)
          break
        case 'antivirusCheck':
          div.style.background = '#470A91'
          setStatusUpload(message.message)
          break
        case 'virusInfected':
          setStatusUpload(message.message)
          break
        case 'done':
          div.style.background = '#00ff00'
          setStatusUpload(message.message)
          runOnLoad(message.data)
          break
        case 'error':
          div.style.background = '#ff0000'
          runError(message.message.includes('No "channel-assets-conditioning')
            ? 'There was an error on uploading your file. Something went wrong. No "channel-assets-conditioning flow'
            : message.message)
          setStatusUpload(message.message)
          break
        default:
          console.log(`Unhandled event: ${message.event}`)
      }
    })

    socket.on('connect', () => {
      console.log('Connected to websocket')
    })

    socket.on('disconnect', () => {
      console.log('Disconnected websocket')
    })

    return () => {
      socket.close()
    }
    // eslint-disable-next-line
  }, [])  // empty array means it will only run on mount and unmount

  const handleFileUpload = async () => {
    if (statusUpload === '') {
      // 1MB chunks
      const chunkSize = 1024 * 1024
      const totalChunks = Math.ceil(file?.size / chunkSize)

      let lastResponse
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, file?.size)
        const chunk = file?.slice(start, end)

        const formData = new FormData()
        formData.append('file', chunk)
        formData.append('ischunk', true)
        formData.append('chunkNumber', i + 1)
        formData.append('totalChunks', totalChunks)

        headers['ischunk'] = true
        headers['filename'] = file?.name
        headers['totalfilesize'] = file?.size

        try {
          lastResponse = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: headers
          })

          if (lastResponse.status === 413) {
            setErrorSend(true);
            setStatusUpload('file have large size.')
            props.onError('You can upload a file smaller than 1024 MB. Your current file is larger than this size.')
            break
          }
        } catch (e) {
          setErrorSend(true);
          setStatusUpload('failed to upload.')
          props.onError('problem with upload file.')
          break
        }
      }
    }
  }

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.wrapper}>
        <input
          type="file"
          id="upload-file"
          hidden="hidden"
          onChange={(event) => {
            // max file size = 1024 MB (calculated in bytes)
            if (event?.target?.files[0]?.size >= maxFileSize) {
              runError('You can upload a file smaller than 1024 MB. Your current file is larger than this size.')
            } else {
              setStatusUpload('')
              setFile(event?.target?.files[0])
              setFileName(event?.target?.files[0].name)
            }
          }}
        />
        <button
          onClick={() => document.getElementById('upload-file').click()}
          className={styles.selectButton}
        >
          select file
        </button>
        <button
          className={`${styles.submitBtn} ${file ? styles.active : ''}`}
          disabled={!file}
          onClick={handleFileUpload}
        >
          upload
        </button>
      </div>
      <div className={styles.uplopadStatusWrapper}>
        <span>{fileName}</span>
        <span className={`${errorSend ? styles.error : ''}`}>{statusUpload}</span>
      </div>
      <div className={`${errorSend ? styles.progressBarWrapperError : styles.progressBarWrapper}`}>
        <div className={`${errorSend ? styles.progressBarError : styles.progressBar}`} id={"progressBar"}></div>
      </div>
    </div>
  );
};

export default FileUploadWithChunk;
