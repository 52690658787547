export const Title = {
  success: 'Success',
  error: 'Error',
  warning: 'Are you sure?',
  info: 'Warning' // dont blame me, "warning" was already used for confirmation ...
}

export const Icon = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'warning'
}

export const Message = {
  success: 'Action succeeded!',
  error: 'Action failed!',
  warning: 'You will not be able to recover this item!',
  info: 'Information',
  confirmButtonText: 'Ok',
  cancelButtonText: 'Cancel',
}
