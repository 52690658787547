import React, { useEffect, useRef, useState } from 'react'
import { StateInspector } from 'reinspect'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { showErrorMessage } from './components/Alerts/Alerts'

import indexRoutes from './routes/index.jsx'
import './assets/scss/material-dashboard-pro-react.css?v=1.4.0'
import {
  initialState,
  reducer,
  actions
} from './state/entity'
import {
  initialState as uiInitialState,
  reducer as uiReducer,
  actions as uiActions
} from './state/ui.js'
import {
  initialState as usersInitialState,
  reducer as usersReducer,
  actions as usersActions
} from './state/users'
import {
  initialState as systemsInitialState,
  reducer as systemsReducer,
  actions as systemsActions
} from './state/systems'
import DispatchContext from './state/DispatchContext'
import useReducerWithEffects from './state/useReducerWithEffects'
import backend from "./utils/backend"
import versionJson from "./version.json"

if (window.location.host === 'admin.serverside.ai') {
  ReactGA.initialize('UA-116929900-3');
  ReactGA.pageview('/app');
}

let isLogined = { }

// to mitigate "Uncaught ReferenceError: process is not defined" error:
window.process = {
  platform: "linux"
}

const ContextProvider = props => {
  const [channels, dispatchChannels] = useReducerWithEffects(reducer('channels'), initialState, {}, 'channels')
  const [users, dispatchUsers] = useReducerWithEffects(usersReducer, usersInitialState, {}, 'users')
  const [systems, dispatchSystems] = useReducerWithEffects(systemsReducer, systemsInitialState, {}, 'systems')
  const [ui, dispatchUI] = useReducerWithEffects(uiReducer, uiInitialState, {}, 'ui')
  const [authProps={}, setAuthProps] = useState({})
  const {
    error,
    isLoading,
    logout,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()

  const context = {
    state: {
      channels,
      users,
      systems,
      ui
    },
    actions: {
      channels: actions('channels', dispatchChannels),
      users: usersActions(dispatchUsers),
      systems: systemsActions(dispatchSystems),
      ui: uiActions(dispatchUI)
    }
  }

  const contextRef = useRef(context)

  useEffect(() => {
    if (!authProps.clientId) {
      getAuthProps()
    }
  }, [authProps])

  async function getAuthProps () {
    try {
      const respons = await backend.getAuthProps()
      setAuthProps(respons)
    } catch (e) {
      console.error('Error get Auth props', e)
    }
  }

  /**
   * here we set up centralized resize listener:
   */
  useEffect(() => {
    const resizeFunction = () => {
      contextRef.current.state.ui.getDebounce()(() => {
        contextRef.current.actions.ui.setWindowSize(window.innerWidth, window.innerHeight)
        contextRef.current.actions.ui.callResizers()
      }, 500)()
    }
    window.addEventListener("resize", resizeFunction)
    contextRef.current.actions.ui.setWindowSize(window.innerWidth, window.innerHeight)
    contextRef.current.actions.users.getMe()
  }, [])

  useEffect(() => {
    if (!isLoading && localStorage.getItem('token') && window.location.pathname === '/') {
      window.location.pathname = 'app/dashboard'
    }
    const isToken = localStorage.getItem('token')
    if (!isLoading && isAuthenticated && !isToken && !error) {
      getToken(isLogined.returnTo)
    }
    if (error) {
      showErrorMessage(error.message, () => {
        resetState()
      })
      return
    }
    if (!isLoading && !isAuthenticated && !isToken && !isLogined.returnTo && !window.location.pathname.includes('register') && !window.location.pathname.includes('openapi')) {
      redirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, isLogined])

  async function redirect () {
    await loginWithRedirect({ appState: { returnTo: 'app/dashboard' } })
  }

  async function resetState () {
    if (error) {
      await logout({
        logoutParams: {
          returnTo: window.location.path,
          client_id: authProps.clientId
        }
      })
      const origin = window.location.origin
      window.location.replace(origin)
      window.localStorage.clear()
    }
  }

  async function getToken (moveTo) {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: authProps.audience,
          scope: "read_write:all"
        },
      })

      if (!token) {
        throw new Error('No Token Transferred')
      } else {
        localStorage.setItem('token', token)
        window.location.pathname = moveTo
        await props.context.actions.users.getMe()
      }
    } catch (e) {
      console.error(e)

      await logout({
        logoutParams: {
          returnTo: window.location.origin,
          client_id: authProps.clientId
        }
      })
    }
  }

  return (
    <DispatchContext.Provider value={context}>
      {props.children}
    </DispatchContext.Provider>
  )
}

const version= `ver. 2.${versionJson.version.trim() + " - " + new Date().toISOString()}`

backend.getAuthProps().then((authProps) => {
  ReactDOM.render(
    <Auth0Provider
      domain={authProps.domain}
      clientId={authProps.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: authProps.audience,
        scope: "read:current_user update:current_user_metadata",
        version: version
      }}
      onRedirectCallback={(appState) => {
        isLogined = { ...appState, authProps }
      }}
    >
      <StateInspector name="nemo2app">
        <ContextProvider>
          <BrowserRouter>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return <Route
                  path={prop.path}
                  component={prop.component}
                  key={key}
                />
              })}
            </Switch>
          </BrowserRouter>
        </ContextProvider>
      </StateInspector>
    </Auth0Provider>,
    document.getElementById("root")
  )
})
